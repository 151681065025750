/* eslint no-unused-vars: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-empty-function: 0 */
import React, { createContext, useContext, useState } from 'react'

interface InitialContext {
  formData: {
    mobileNumber: string
    otpCode: string

    fullName: string
    email: string
    nationalId: string

    city: string
    address: string
    buildingNumber: string
    floorNumber: string
    apartmentNumber: string

    selectedBankId: string
    shareBankDataExpiryDate: Date

    loanAmount: string
    loanPlanId: string
    loanReturnStartDate: number
    loanReceivingAccountId: string

    isCloseToPublicFigure: boolean
    isForeignCitizen: boolean
    isRepresentingBusiness: boolean

    creditCardNumber: string
    creditCardExpiryDate: string
    creditCardCvv: string
  }
  onChangeFormData: (_key: string, _value: any) => void
  leadId: string
  setLeadId: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const initialContext: InitialContext = {
  formData: {
    mobileNumber: '',
    otpCode: '',
    fullName: 'איציק גיני',
    email: '',
    nationalId: '',

    city: '',
    address: '',
    buildingNumber: '',
    floorNumber: '',
    apartmentNumber: '',

    selectedBankId: '',
    shareBankDataExpiryDate: new Date(),

    loanAmount: '',
    loanPlanId: '',
    loanReturnStartDate: 0,
    loanReceivingAccountId: '',

    isCloseToPublicFigure: false,
    isForeignCitizen: false,
    isRepresentingBusiness: false,

    creditCardNumber: '',
    creditCardExpiryDate: '',
    creditCardCvv: '',
  },
  onChangeFormData: (_key, _value) => {},
  leadId: '',
  setLeadId: (_str) => {},
  loading: false,
  setLoading: (_bool) => {},
}

// init context
const FormContext = createContext(initialContext)

// export the consumer
export function useForm(): InitialContext {
  return useContext(FormContext)
}

// export the provider (handle all the logic here)
export function FormProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [formData, setFormData] = useState(initialContext.formData)
  const [leadId, setLeadId] = useState(initialContext.leadId)
  const [loading, setLoading] = useState(initialContext.loading)

  const onChangeFormData = (key: string, value: any): void => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  return (
    <FormContext.Provider
      value={{
        formData,
        onChangeFormData,
        leadId,
        setLeadId,
        loading,
        setLoading,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
