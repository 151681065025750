/* eslint react/no-unescaped-entities: 0 */
import React from 'react'
import { makeStyles } from '@mui/styles'
import { Divider, Typography } from '@mui/material'
import { CurrencyExchangeRounded as CurrencyIcon, MailOutlineRounded as MailIcon } from '@mui/icons-material'
import { Button, LinkButton } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import formatToCurrency from '../../functions/formatToCurrency'
import GrayTextWrapper from '../../components/GrayTextWrapper'

const useStyles = makeStyles(
  () => ({
    infoWrap: {
      width: '100%',
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      direction: 'rtl',
    },
    infoAvatar: {
      margin: '0.5rem',
      padding: '0.25rem',
      border: '1px solid gray',
      borderRadius: '100%',
    },
    infoText: {
      margin: '0',
      textAlign: 'right',
    },
  }),
  { name: 'StepTwentyThree' }
)

const DialogStep = (): JSX.Element => {
  const { formData } = useForm()
  const classes = useStyles()

  const clickNext = (): void => {
    // https://signnow.com/s/9BtfTZYK
    window.open('https://signnow.com/s/9BtfTZYK', '_blank')
  }

  // This is the UI Itzik wants for his demos
  return (
    <StepContainer>
      <div>
        <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', m: 2 }}>
          אישור ההלוואה
        </Typography>
        <Typography variant='h5' sx={{ direction: 'rtl', mb: 3 }}>
          הלוואה בסכום:
          <br />
          <strong>{formatToCurrency(Number(formData['loanAmount']))}</strong>
        </Typography>
        <GrayTextWrapper darker bigger noBorder>
          תוכל לראות את ההלוואה בחשבונך תוך יום עסקים מרגע חיתום הסכם ההלוואה
        </GrayTextWrapper>
      </div>

      <Button label='לחיתום הסכם ההלוואה' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.infoWrap}>
          <CurrencyIcon fontSize='large' className={classes.infoAvatar} />
          <p className={classes.infoText}>
            החיוב מותאם ליום כניסת המשכורת שלך.
            <br />
            <strong>החיוב הראשון יתבצע ב- {new Date(formData['loanReturnStartDate']).toLocaleDateString()}</strong>
          </p>
        </div>
        <div className={classes.infoWrap}>
          <MailIcon fontSize='large' className={classes.infoAvatar} />
          <p className={classes.infoText}>
            אישור ההלוואה ומסמך התנאים המלא נשלחו אל המייל בכתובת:
            <strong> {formData['email']}</strong>
          </p>
        </div>

        <Divider sx={{ m: 2 }} />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LinkButton label='שמור אישור כתמונה' fontSize='0.8rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
          <LinkButton label='פרטי ההלוואה' fontSize='0.8rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
          <LinkButton label='פרטי חשבון' fontSize='0.8rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
        </div>
      </div>
    </StepContainer>
  )

  // This is the exact UI given from Phoenix UI plans
  // return (
  //   <StepContainer>
  //     <div>
  //       <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', m: 2 }}>
  //         אישור ההלוואה
  //       </Typography>
  //       <Typography variant='h5' sx={{ direction: 'rtl', mb: 3 }}>
  //         הלוואה בסכום:
  //         <br />
  //         <strong>{formatToCurrency(Number(formData['loanAmount']))}</strong>
  //         <br />
  //         הועברה לחשבון שלך בהצלחה!
  //       </Typography>
  //       <GrayTextWrapper darker bigger noBorder>
  //         תוכל לראות את ההלוואה בחשבונך תוך יום עסקים
  //       </GrayTextWrapper>
  //     </div>

  //     <div style={{ display: 'flex', flexDirection: 'column' }}>
  //       <div className={classes.infoWrap}>
  //         <CurrencyIcon fontSize='large' className={classes.infoAvatar} />
  //         <p className={classes.infoText}>
  //           החיוב מותאם ליום כניסת המשכורת שלך.
  //           <br />
  //           <strong>החיוב הראשון יתבצע ב- {new Date(formData['loanReturnStartDate']).toLocaleDateString()}</strong>
  //         </p>
  //       </div>
  //       <div className={classes.infoWrap}>
  //         <MailIcon fontSize='large' className={classes.infoAvatar} />
  //         <p className={classes.infoText}>
  //           אישור ההלוואה ומסמך התנאים המלא נשלחו אל המייל בכתובת:
  //           <strong> {formData['email']}</strong>
  //         </p>
  //       </div>

  //       <Divider sx={{ m: 2 }} />

  //       <div style={{ display: 'flex', justifyContent: 'center' }}>
  //         <LinkButton label='שמור אישור כתמונה' fontSize='0.8rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
  //         <LinkButton label='פרטי ההלוואה' fontSize='0.8rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
  //         <LinkButton label='פרטי חשבון' fontSize='0.8rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
  //       </div>

  //       <Button label='לקבלת הלוואה' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
  //     </div>
  //   </StepContainer>
  // )
}

export default DialogStep
