import React from 'react'
import { Button } from '@everybyte-io/components'
import { Avatar, InputAdornment, TextField, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData } = useForm()

  const clickNext = (): void => {
    if (!formData['creditCardNumber'] || !formData['creditCardExpiryDate'] || !formData['creditCardCvv']) {
      alert('אנא הזן את כל הפרטים')

      return
    }

    nextStep()
  }

  return (
    <StepContainer>
      <div>
        <Typography variant='h5' fontWeight='bold' sx={{ fontSize: 31 }}>
          פרטי כרטיס אשראי
        </Typography>
        <div dir='rtl' style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
          <div style={{ padding: 7, backgroundColor: 'rgba(242, 242, 242, 1)', display: 'flex', borderRadius: 8 }}>
            <Avatar sx={{ width: 25, height: 25 }}>
              M
            </Avatar>
            <Typography sx={{ paddingRight: 1, textDecoration: 'underline', color: '#524f4f', fontSize: 14 }}>
              למה אנחנו צריכים את פרטי האשראי שלך?
            </Typography>
          </div>
        </div>
        <form dir='rtl' >
          <TextField
            placeholder='מספר כרטיס'
            value={formData['creditCardNumber']}
            onChange={(e) => onChangeFormData('creditCardNumber', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CreditCardIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            margin='normal'
          />
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder='תאריך תפוגה'
              value={formData['creditCardExpiryDate']}
              onChange={(e) => onChangeFormData('creditCardExpiryDate', e.target.value)}
              sx={{ paddingLeft: 1 }}
              fullWidth
              margin='normal'
            />
            <TextField
              placeholder='קוד אבטחה'
              value={formData['creditCardCvv']}
              onChange={(e) => onChangeFormData('creditCardCvv', e.target.value)}
              sx={{ paddingRight: 1 }}
              fullWidth
              margin='normal'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ErrorOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </form>

        {/* <NumPad placeholder='10,000' value={formData['loanAmount']} setValue={(v) => onChangeFormData('loanAmount', v)} /> */}
      </div>

      <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
    </StepContainer>
  )
}

export default DialogStep
