import React, { Fragment, useState } from 'react'
import { DialogWrapper, StepContext } from '@everybyte-io/components'
import openFinanceLogo from '../../assets/open-finance-logo.png'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import StepSix from './StepSix'
import StepSeven from './StepSeven'
import StepEight from './StepEight'
import StepNine from './StepNine'
import StepTen from './StepTen'
import StepEleven from './StepEleven'
import StepTwelve from './StepTwelve'
import StepThirteen from './StepThirteen'
import StepFourteen from './StepFourteen'
import StepFifteen from './StepFifteen'
import StepSixteen from './StepSixteen'
import StepSeventeen from './StepSeventeen'
// import StepEighteen from './StepEighteen'
// import StepNineteen from './StepNineteen'
import StepTwenty from './StepTwenty'
import StepTwentyOne from './StepTwentyOne'
import StepTwentyTwo from './StepTwentyTwo'
import StepTwentyThree from './StepTwentyThree'

const Journey = ({ admin }: { admin?: boolean }): JSX.Element => {
  const [logo, setLogo] = useState(!admin ? openFinanceLogo : '')

  const onClickClose = (): void => {
    if (window.confirm('Are you sure you want to abort this payment?')) {
      // parentComms('abort')
      // parentComms('close')

      alert('TODO: handle this click event')
    }
  }

  return (
    <DialogWrapper
      isRtl
      numberOfPhases={21}
      preventBackAtSteps={[11, 12, 21]}
      onClickClose={onClickClose}
      Image={() =>
        admin && !logo ? <input placeholder='logo url' onChange={(e) => setLogo(e.target.value)} /> : <img src={logo} alt='Everybyte' height='40' />
      }
    >
      <StepContext.Consumer>
        {({ step, setStep, increment }): JSX.Element | null => {
          return (
            <Fragment>
              {step === 1 ? (
                <StepOne nextStep={increment} />
              ) : step === 2 ? (
                <StepTwo nextStep={increment} />
              ) : step === 3 ? (
                <StepThree nextStep={increment} />
              ) : step === 4 ? (
                <StepFour nextStep={increment} />
              ) : step === 5 ? (
                <StepFive nextStep={increment} />
              ) : step === 6 ? (
                <StepSix nextStep={increment} />
              ) : step === 7 ? (
                <StepSeven nextStep={increment} />
              ) : step === 8 ? (
                <StepEight nextStep={increment} />
              ) : step === 9 ? (
                <StepNine nextStep={increment} />
              ) : step === 10 ? (
                <StepTen nextStep={increment} />
              ) : step === 11 ? (
                <StepEleven nextStep={increment} />
              ) : step === 12 ? (
                <StepTwelve nextStep={increment} />
              ) : step === 13 ? (
                <StepThirteen nextStep={increment} setStep={setStep} />
              ) : step === 14 ? (
                <StepFourteen nextStep={increment} />
              ) : step === 15 ? (
                <StepFifteen nextStep={increment} />
              ) : step === 16 ? (
                <StepSixteen nextStep={increment} />
              ) : step === 17 ? (
                <StepSeventeen nextStep={increment} />
              )
                // ) : step === 18 ? (
                //   <StepEighteen nextStep={increment} />
                // ) : step === 19 ? (
                //   <StepNineteen nextStep={increment} />
                // ) 
                : step === 18 ? (
                  <StepTwenty nextStep={increment} />
                ) : step === 19 ? (
                  <StepTwentyOne nextStep={increment} />
                ) : step === 20 ? (
                  <StepTwentyTwo nextStep={increment} />
                ) : step === 21 ? (
                  <StepTwentyThree />
                ) : null}
            </Fragment>
          )
        }}
      </StepContext.Consumer>
    </DialogWrapper>
  )
}

export default Journey
