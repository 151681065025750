import React from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, Typography, SvgIcon } from '@mui/material'
import { Favorite as FavoriteIcon, Timer as TimerIcon } from '@mui/icons-material/'
import { Button, LinkButton } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import GrayTextWrapper from '../../components/GrayTextWrapper'

const useStyles = makeStyles(
  () => ({
    privacyWrapper: {
      margin: '5px 0 !important',
      padding: '12px 4px !important',
      backgroundColor: 'whitesmoke',
      border: 'none',
      borderRadius: 11,
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
    },
    privacyText: {
      marginLeft: 'auto !important',
    },
    btnWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'StepEight' }
)

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData } = useForm()
  const classes = useStyles()

  const InfoBox = ({ title, description, Icon }: { title: string; description: string; Icon: typeof SvgIcon }): JSX.Element => {
    return (
      <div className={classes.privacyWrapper}>
        <div className={classes.privacyText}>
          <Typography variant='body1' fontWeight='bold' style={{ direction: 'rtl' }}>
            {title}
          </Typography>
          <Typography variant='body1' fontSize='0.9rem' color='gray' style={{ direction: 'rtl' }}>
            {description}
          </Typography>
        </div>
        <Avatar component={() => <Icon color='action' fontSize='large' sx={{ m: 1 }} />} sx={{ backgroundColor: 'transparent' }} />
      </div>
    )
  }

  return (
    <StepContainer>
      <div>
        <Typography variant='h5' fontWeight='bold'>
          {formData['fullName'].split(' ')[0]}, כדי שנוכל לתת לך הלוואה שמותאמת עבורך
        </Typography>
        <Typography variant='h6'>נשמח לקבל מידע ישירות מהבנק שלכם</Typography>
        <GrayTextWrapper darker>מידע מחשבון בעל ת.ז: {formData['nationalId']}</GrayTextWrapper>
      </div>

      <div>
        <InfoBox title='הפרטיות שלכם חשובה לנו' description='אנחנו לא שומרים את המידע או עושים בו שימוש נוסף' Icon={FavoriteIcon} />
        <InfoBox
          title='למה כדאי לכם לשתף את המידע?'
          description='שיתוף המידע חוסך מאיתנו לבקש מכם את כל הפרטים וחוסך לכם זמן ושלבים בתהליך'
          Icon={TimerIcon}
        />
      </div>

      <div className={classes.btnWrapper}>
        <LinkButton label='לא כרגע' fontSize='0.9rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
        <Button label='המשך' onClick={nextStep} invert textAlign='center' style={{ borderRadius: '100px' }} />
      </div>
    </StepContainer>
  )
}

export default DialogStep
