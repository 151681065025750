/* eslint no-unused-vars: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { useState } from 'react'
import {
  Avatar,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material'
import { Button } from '@everybyte-io/components'
import StepContainer from '../../components/StepContainer'

const DialogStep = ({ nextStep, setStep }: { nextStep: () => void; setStep: (num: number) => void }): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const [dateSelected, setDateSelected] = useState('1st')
  const [firstDate, setFirstDate] = useState('')
  const [secondDate, setSecondDate] = useState('')

  const clickNext = (): void => {
    if ((dateSelected === '1st' && !firstDate) || (dateSelected === '2nd' && !secondDate)) {
      alert('אנא הזן את כל השדות')

      return
    }

    nextStep()
  }

  return (
    <StepContainer>
      <div dir='rtl'>
        <Typography fontWeight='bold' textAlign='start' sx={{ fontSize: 27, lineHeight: 1.3 }}>
          אישור פנייה למאגר האשראי
        </Typography>
      </div>
      <div dir='rtl' style={{ backgroundColor: 'rgba(242, 242, 242, 1)', borderRadius: 5, display: 'flex', padding: 10 }}>
        <div>
          <Avatar sx={{ width: 24, height: 24, marginLeft: 1, marginTop: 0.5 }}>M</Avatar>
        </div>
        <div dir='rtl' style={{ textAlign: 'start' }}>
          <Typography fontWeight='bold' sx={{ fontSize: 14 }}>
            שלב אחרון לפני אישור הלוואה!
          </Typography>
          <Typography sx={{ fontSize: 14, lineHeight: 1.2 }}>פניה למאגר האשראי מאפשרת לנו לתת לכם תנאים טובים יותר בתהליך מהיר</Typography>
        </div>
      </div>
      <div dir='rtl'>
        <Typography textAlign='start' sx={{ lineHeight: 1.1, fontSize: 18, paddingBottom: 2 }}>
          מאשר את הסכמתי לכך שנתוני האשראי לגביי הכוללים במאגר יימסרו ללשכת האשראי לשם עריכת דוח אשראי
        </Typography>
        <Divider sx={{ width: '100%', background: 'black' }} />
        <div style={{ display: 'flex', paddingTop: 15 }}>
          <div dir='rtl' style={{ overflowY: 'scroll', height: 250 }}>
            <Typography paragraph textAlign='start'>
              התאם לחוק נתוני האשראי הוקם מאגר נתוני האשראי אשר מופעל על ידי בנק ישראל. אין באפרותינו לקבל נתוני אשראי, לרבות נתונים היסטורים המופיעים
              במאגר לגבך, ידוע לי שנתוני האשראי הכלולים לגביי במאגר כוללים, בין השארף תנונים לגבי עסקאות אשראי שבציעתי וכן נתונים שונים מכונס נכסים
              הרשמי, הוצאה לפועל ובנק ישראל.
            </Typography>
            <Typography textAlign='start' fontWeight='bold'>
              תוקף הסכמה
            </Typography>
            <FormControl sx={{ paddingBottom: 2 }}>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={dateSelected}
                name='radio-buttons-group'
                value={dateSelected}
                onChange={({ target }) => setDateSelected(target.value)}
              // dateSelected, setDateSelected
              >
                <Typography textAlign='start'>אם ההלוואה תועמד ההסכמה שלי בתוקף עד למועד סיום ההלוואה או עד תאריך (המוקדם מבניהם)</Typography>
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value='1st' control={<Radio />} label='' sx={{ margin: 0 }} />
                  <FormControl fullWidth>
                    <InputLabel id='1st-select-label'>בחר תאריך</InputLabel>
                    <Select
                      value={firstDate}
                      onChange={({ target }) => setFirstDate(target.value)}
                      labelId='1st-select-label'
                      id='demo-simple-select'
                      label='Age'
                      disabled={dateSelected === '1st' ? false : true}
                    >
                      <MenuItem value={1}>חודש</MenuItem>
                      <MenuItem value={2}>חצי שנה</MenuItem>
                      <MenuItem value={3}>שנה</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <Typography textAlign='start'>אם ההלוואה לא תועמד ההסכמה שלי בתוקף עד לתאריך ה 25.07.2022 או עד תאריך (המוקדם מבניהם)</Typography>
                <div style={{ display: 'flex' }}>
                  <FormControlLabel value='2nd' control={<Radio />} label='' sx={{ margin: 0 }} />
                  <FormControl fullWidth>
                    <InputLabel id='2nd-select-label'>בחר תאריך</InputLabel>
                    <Select
                      value={secondDate}
                      onChange={({ target }) => setSecondDate(target.value)}
                      labelId='2nd-select-label'
                      id='demo-simple-select'
                      label='Age'
                      disabled={dateSelected === '2nd' ? false : true}
                    >
                      <MenuItem value={1}>חודש</MenuItem>
                      <MenuItem value={2}>חצי שנה</MenuItem>
                      <MenuItem value={3}>שנה</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </RadioGroup>
            </FormControl>
            <Divider sx={{ width: '100%', background: 'black' }} />
            <Typography textAlign='start' fontWeight='bold' paddingTop={2}>
              שים לב
            </Typography>
            <Typography textAlign='start' paragraph>
              נתוני האשראי האמרוים יוכלו לשמש את לשכת האשראי גם למתן שירותים לנתוני האשראי לרבות דירוג או ייעוץ, לתקופה שהוסכמה לעיל והכל בכפוף לחוק.
            </Typography>
            <Typography textAlign='start' paragraph>
              מסירת המידע תלויה בהסכמתך, ואין אתה מחויב לתת את הסכמתך.
            </Typography>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button label='המשך ללא אישור' onClick={() => setShowModal(true)} textAlign='center' style={{ borderRadius: '100px' }} />
        <Button label='מאושר' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      </div>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div
          style={{
            maxWidth: '330px',
            padding: '1rem',
            backgroundColor: 'white',
            borderRadius: '1rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Avatar
            variant='circular'
            sx={{
              width: '50px',
              height: '50px',
              position: 'absolute',
              top: '-9%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
          <div style={{ display: 'flex' }}>
            <Typography variant='h5' textAlign='center' fontWeight='bold' paddingTop={1}>
              המשך ללא אישור למאגר האשראי
            </Typography>
          </div>

          <Typography sx={{ direction: 'rtl', textAlign: 'center', color: 'gray', margin: '2rem 0' }}>
            במידה ולא תאשר את המעבר למאגר האשראי, לצערנו <b>לא נוכל לתת לך הלוואה.</b>
            <br />
            אנחנו צריכים שההלוואה תהיה בטוחה גם עבורינו.
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'column', height: 110, justifyContent: 'space-around' }}>
            <Button label='חזור לעמוד אישור' onClick={() => setShowModal(false)} invert textAlign='center' style={{ borderRadius: '100px' }} />
            <Button label='אוקיי, הבנתי - אני לא מאשר' onClick={() => setShowModal(false)} textAlign='center' style={{ borderRadius: '100px' }} />
          </div>
        </div>
      </Modal>
    </StepContainer >
  )
}

export default DialogStep
