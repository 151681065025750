import React from 'react'
import { Button, Loader } from '@everybyte-io/components'
import { TextField, Typography } from '@mui/material'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import updateLead from '../../functions/api/update-lead'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading, leadId } = useForm()

  const clickNext = async (): Promise<void> => {
    if (formData['city'] && formData['address']) {
      setLoading(true)

      try {
        const res = await updateLead(leadId, {
          nationalId: formData['nationalId'],
          creditRequested: Number(formData['loanAmount']),
          fullAddress: `${formData['city']}, ${formData['address']}${formData['buildingNumber'] ? ` ${formData['buildingNumber']}` : ''}${formData['apartmentNumber'] ? `/${formData['apartmentNumber']}` : ''}`,
        })

        if (res.status === 200) {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  const formStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    flex: 1,
  }

  return (
    <StepContainer>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', placeContent: 'space-evenly' }}>
        <div dir='rtl' style={{ paddingTop: 8 }}>
          <Typography variant='h5' fontWeight='bold' sx={{ fontSize: 31 }}>
            {formData['fullName'].split(' ')[0]},
          </Typography>
          <Typography variant='h5' fontWeight='bold' sx={{ fontSize: 31 }}>
            מהי כתובת המגורים שלך?
          </Typography>
        </div>
        <form dir='rtl' style={formStyle}>
          <TextField placeholder='יישוב' value={formData['city']} onChange={(e) => onChangeFormData('city', e.target.value)} margin='normal' />
          <TextField placeholder='רחוב' value={formData['address']} onChange={(e) => onChangeFormData('address', e.target.value)} margin='normal' />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              placeholder='מספר בית'
              value={formData['buildingNumber']}
              onChange={(e) => onChangeFormData('buildingNumber', e.target.value)}
              margin='normal'
              sx={{ width: '45%' }}
            />
            <TextField
              placeholder='קומה'
              value={formData['floorNumber']}
              onChange={(e) => onChangeFormData('floorNumber', e.target.value)}
              margin='normal'
              sx={{ width: '45%' }}
            />
          </div>
          <TextField
            placeholder='דירה'
            value={formData['apartmentNumber']}
            onChange={(e) => onChangeFormData('apartmentNumber', e.target.value)}
            margin='normal'
            sx={{ width: '45%' }}
          />
          <div></div>
        </form>
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
