import React from 'react'
import { Typography } from '@mui/material'
import { Button } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import GrayInfoBox from '../../components/GrayInfoBox'
import bankOptions from '../../data/bank-options.json'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData } = useForm()

  const clickNext = (): void => {
    if (formData['selectedBankId']) {
      nextStep()
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <div>
        <Typography variant='h5' fontWeight='bold' sx={{ direction: 'rtl' }}>
          באיזה בנק מתנהל חשבונך?
        </Typography>
        <Typography variant='body1' fontWeight='bold' sx={{ direction: 'rtl', color: 'gray', m: 1 }}>
          ניתן לבחור בנק אחד
        </Typography>
        <GrayInfoBox description='ככל שתשתף אותנו ביותר מידע, נוכל לדייק את ההצעה ולתת לך הלוואה שמותאמת עבורך.' />
      </div>

      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
        }}
      >
        {bankOptions.map((obj) => {
          const selected = formData['selectedBankId'] === obj.id

          return (
            <div
              key={obj.id}
              onClick={() => onChangeFormData('selectedBankId', obj.id)}
              style={{
                height: '70px',
                width: '42%',
                margin: '0.5rem auto',
                padding: 'auto',
                backgroundColor: selected ? 'lightgrey' : 'whitesmoke',
                border: selected ? '2px solid gray' : '2px solid lightgrey',
                borderRadius: '11px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              <input
                type='checkbox'
                readOnly
                checked={selected}
                style={{
                  position: 'absolute',
                  top: '1px',
                  right: '1px',
                }}
              />
              <img src={obj.imageUrl} style={{ width: '100px' }} />
            </div>
          )
        })}
      </div>

      <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
    </StepContainer>
  )
}

export default DialogStep
