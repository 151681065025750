import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './utils/theme'
import { FormProvider } from './contexts/FormContext'
import Journey from './views/Journey'

const AppProviders = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormProvider>
        <BrowserRouter>
          <div>
            <Switch>
              <Route path='/' exact component={() => <Journey />} />
              <Route path='/admin' exact component={() => <Journey admin />} />              
            </Switch>
          </div>
        </BrowserRouter>
      </FormProvider>
    </ThemeProvider>
  )
}

export default AppProviders
