import React from 'react'
import { useMediaQuery } from '@mui/material'

const StepContainer = ({ children, style = {} }: {
  children: JSX.Element | Array<JSX.Element | string | null>,
  style?: React.CSSProperties
}): JSX.Element => {
  const isMobile = useMediaQuery('(max-width: 550px)')

  return (
    <div
      style={{
        height: isMobile ? '100vh' : '600px',
        width: isMobile ? '100%' : '350px',
        padding: '0 0 1rem 0',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'space-between',
        ...style
      }}
    >
      {children}
    </div>
  )
}

export default StepContainer
