import React from 'react'
import axios from 'axios'
import { Typography } from '@mui/material'
import { Button, LinkButton, Loader } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import NumPadInput from '../../components/NumPad/NumPadInput'
import { LOAN_JOURNEY_SERVICE } from '../../consts'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading } = useForm()

  const clickSendAgain = async (): Promise<void> => {
    setLoading(true)

    try {
      const res = await axios.post(`${LOAN_JOURNEY_SERVICE}/send-otp`, {
        phoneNumber: formData['mobileNumber'],
      })

      if (res.status !== 200 && res.data.status === 'pending') {
        console.warn('unexpected response', res)
      }
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const clickNext = async (): Promise<void> => {
    if (formData['otpCode']) {
      setLoading(true)

      try {
        const res = await axios.post(`${LOAN_JOURNEY_SERVICE}/verify-otp`, {
          phoneNumber: formData['mobileNumber'],
          otpCode: formData['otpCode']
        })

        if (res.status === 200 && res.data.status === 'approved') {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <br />

      <div>
        <Typography variant='h5' fontWeight='bold' dir='rtl'>
          שלחנו קוד חד פעמי למספר:
        </Typography>
        <Typography variant='h6' fontWeight='bold'>
          {formData['mobileNumber']}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LinkButton label='קוד בשיחה קולית' fontSize='0.8rem' onClick={() => alert('TODO: handle this click event')} />
          <LinkButton label='שלח שוב' fontSize='0.8rem' onClick={clickSendAgain} />
        </div>
        {/* <NumPad placeholder='* * * * * *' value={formData['otpCode']} setValue={(v) => onChangeFormData('otpCode', v)} /> */}
        <NumPadInput placeholder='* * * * * *' value={formData['otpCode']} setValue={(v) => onChangeFormData('otpCode', v)} />
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
