import React, { useEffect, useState } from 'react'
import { Button, Loader } from '@everybyte-io/components'
import { Avatar, Grid, Slider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import loanPlans from '../../data/loan-plans.json'
import updateLead from '../../functions/api/update-lead'

const useStyle = makeStyles(() => ({
  gridContainer: {
    background: 'rgba(242, 242, 242, 1)',
    border: '3px solid rgba(51, 51, 51, 1)',
    borderRadius: 5,
  },
}))

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const classes = useStyle()
  const { formData, onChangeFormData, loading, setLoading, leadId } = useForm()
  const chosenPlan = loanPlans.find((plan) => plan.id === formData['loanPlanId']) ?? loanPlans[0]

  const [sliderValue, setSliderValue] = useState(chosenPlan.period)

  const getLoanMonthlyAmount = (): string => {
    const loanAmount = Number(formData['loanAmount'])
    const loanWithInterest = loanAmount + (loanAmount + chosenPlan.interest) / 100
    const loanMonthlyAmount = Math.round(loanWithInterest / Number(chosenPlan.period))

    return loanMonthlyAmount + ' ₪'
  }

  const getLoanPlanInterest = (): string => {
    return chosenPlan.interestType + ' + ' + chosenPlan.interest + '%'
  }

  const handleChangeSlider = (event: Partial<Event>, newValue: number | any): any => {
    const selected = loanPlans.find((plan) => Number(plan.period) == newValue)

    if (selected) {
      onChangeFormData('loanPlanId', selected?.id)
    }

    setSliderValue(newValue)
  }

  useEffect(() => {
    handleChangeSlider({}, chosenPlan.period)
  }, [])

  const clickNext = async (): Promise<void> => {
    if (formData['loanPlanId']) {
      setLoading(true)

      try {
        const res = await updateLead(leadId, {
          creditTerm: (loanPlans.find((plan) => plan.id === formData['loanPlanId']) ?? loanPlans[0]).period,
        })

        if (res.status === 200) {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  const GridLoanDetails = ({ id, loanMonthlyAmount, loanPeriod, interest }: any): JSX.Element => (
    <Grid
      container
      className={classes.gridContainer}
      style={{
        border: formData['loanPlanId'] === id ? '2px solid gray' : '1px solid gray',
        backgroundColor: formData['loanPlanId'] === id ? 'rgb(222, 222, 222)' : 'transparent',
      }}
    >
      <Grid item xs={5}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 4 }}>
          <Typography textAlign='start' sx={{ fontSize: 14 }}>
            סכ״ה החזר חודשי
          </Typography>
          <Typography fontWeight='bold' textAlign='start' sx={{ fontSize: 24 }}>
            {loanMonthlyAmount}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={3.5}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 4, color: 'gray' }}>
          <Typography textAlign='start'>תקופה</Typography>
          <Typography textAlign='start' sx={{ fontSize: 14, color: 'gray' }}>
            {loanPeriod}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={3.5}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 4, color: 'gray' }}>
          <Typography textAlign='start'>ריבית</Typography>
          <Typography textAlign='start' sx={{ fontSize: 14, color: 'gray' }}>
            {interest}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )

  return (
    <StepContainer>
      <div dir='rtl' style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-evenly' }}>
        <Typography variant='h5' fontWeight='bold' sx={{ fontSize: 28 }}>
          כמה תרצו להחזיר בכל חודש?
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
          <div style={{ padding: 5, backgroundColor: 'rgba(242, 242, 242, 1)', display: 'flex', borderRadius: 8 }}>
            <Avatar sx={{ width: 25, height: 25 }}>M</Avatar>
            <Typography sx={{ paddingRight: 1, textDecoration: 'underline' }}>מהי ריבית פריים?</Typography>
          </div>
        </div>

        <div dir='rtl' style={{ padding: '0px 18px' }}>
          <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
            {getLoanMonthlyAmount()}
          </Typography>
          <Slider
            defaultValue={loanPlans[0].period}
            min={6}
            max={18}
            marks={loanPlans.map((plan) => ({
              value: plan.period,
              label: plan.period,
            }))}
            // step={2}
            value={sliderValue}
            onChange={handleChangeSlider}
          />
          <Typography sx={{ fontSize: 14, color: '#7F7F7F' }}>{getLoanPlanInterest()}</Typography>
        </div>

        <div style={{ overflowY: 'scroll', height: 250 }}>
          {loanPlans.map((plan) => {
            const { id, period, interest, interestType } = plan

            const loanAmount = Number(formData['loanAmount'])
            const loanWithInterest = loanAmount + (loanAmount + interest) / 100
            const loanMonthlyAmount = Math.round(loanWithInterest / Number(period))

            return (
              <div
                key={id}
                style={{ margin: 5 }}
                onClick={() => {
                  onChangeFormData('loanPlanId', id)
                  setSliderValue(Number(period))
                }}
              >
                <GridLoanDetails id={id} loanPeriod={period} interest={interest + interestType} loanMonthlyAmount={loanMonthlyAmount + ' ₪'} />
              </div>
            )
          })}
        </div>
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
