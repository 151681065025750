import React from 'react'
import { Button, Loader } from '@everybyte-io/components'
import { Divider, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import Details from '../../components/Details'
import createLead from '../../functions/api/create-lead'

const useStyle = makeStyles(() => ({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(242, 242, 242, 0.43529411764705883)',
    border: 'solid rgba(215, 215, 215, 1)',
    borderRadius: 3,
    alignItems: 'start',
    padding: 10,
  },
  placeholder: {
    position: 'absolute',
    left: '20px',
    top: '0px',
    backgroundColor: 'white',
    padding: '0 20px',
  },
  emailField: {
    '&::placeholder': {
      textAlign: 'end',
    },
  },
}))

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const classes = useStyle()
  const { formData, onChangeFormData, loading, setLoading, setLeadId } = useForm()

  const clickNext = async (): Promise<void> => {
    if (formData['email']) {
      setLoading(true)

      try {
        const res = await createLead({
          firstName: formData['fullName'].split(' ')[0],
          lastName: formData['fullName'].split(' ')[1],
          email: formData['email'],
          phone: formData['mobileNumber'],
        })

        if (res.status === 201 || res.status === 200) {
          setLeadId(res.data.leadId)
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', placeContent: 'space-evenly' }}>
        <div dir='rtl'>
          <Typography color='gray' variant='h6'>
            על פי המידע שהתקבל ממרשם האוכלסין
          </Typography>
          <Typography variant='h5' fontWeight='bold' sx={{ fontSize: 31 }}>
            פרטייך האישיים הם:
          </Typography>
        </div>

        <div dir='rtl' className={classes.detailsContainer}>
          <Details title='שם פרטי ושם משפחה' value={formData['fullName']} />
          <div style={{ display: 'flex', width: '90%', flex: 1, alignSelf: 'center' }}>
            <Divider light sx={{ display: 'flex', flex: 1, height: 10 }} />
          </div>
          <Details title='תעודת זהות' value={formData['nationalId']} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Divider light sx={{ display: 'flex', flex: 1, height: 10, borderColor: '#aaaaaa' }} />
          <h3 dir='rtl'>השלם בבקשה את פרטי הדואר האלקטרוני:</h3>
          <TextField
            placeholder='דואר אלקטרוני (אי-מייל)'
            value={formData['email']}
            onChange={(e) => onChangeFormData('email', e.target.value)}
            InputProps={{
              classes: { input: classes.emailField },
            }}
          />
        </div>
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
