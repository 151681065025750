import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { Button, Loader } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import getHebrewMonth from '../../functions/getHebrewMonth'
import StepContainer from '../../components/StepContainer'
import updateLead from '../../functions/api/update-lead'

const useStyles = makeStyles(
  () => ({
    selection: {
      height: '70px',
      margin: '1rem 0 !important',
      border: '1px solid black',
      borderRadius: '7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    selected: {
      backgroundColor: 'lightgray',
      border: '2px solid black',
    },
  }),
  { name: 'StepSixteen' }
)

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading, leadId } = useForm()
  const classes = useStyles()

  const clickNext = async (): Promise<void> => {
    if (formData['loanReturnStartDate']) {
      setLoading(true)

      try {
        const res = await updateLead(leadId, {
          loanReturnStartDate: new Date(formData['loanReturnStartDate']),
        })

        if (res.status === 200) {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  const getOptionsArray = (): {
    timestamp: number
    hebrewMonthLabel: string
  }[] => {
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate()
    const d = new Date(year, month, day)

    const thisMonth = d.getMonth()
    const arr = []

    for (let i = 0; i < 4; i++) {
      const idx = thisMonth + i
      const timestamp = new Date(d).setMonth(idx)

      arr.push({
        timestamp,
        hebrewMonthLabel: getHebrewMonth(timestamp),
      })
    }

    return arr
  }

  return (
    <StepContainer>
      <div>
        <Typography variant='h5' fontWeight='bold' sx={{ direction: 'rtl' }}>
          מתי תרצו להתחיל לשלם?
        </Typography>
        <Typography variant='h6' color='gray' fontSize='1rem' sx={{ direction: 'rtl', mt: 1 }}>
          אם תרצו תוכלו להתחיל לשלם רק בעוד 3 חודשים
        </Typography>
      </div>

      <div>
        {getOptionsArray().map((obj) => (
          <div
            key={obj.timestamp}
            className={`${classes.selection} ${Number(formData['loanReturnStartDate']) === Number(obj.timestamp) ? classes.selected : ''}`}
            onClick={() => onChangeFormData('loanReturnStartDate', obj.timestamp)}
          >
            התחלת תשלום בחודש {obj.hebrewMonthLabel} {new Date(obj.timestamp).getFullYear()}
          </div>
        ))}
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
