import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { Button } from '@everybyte-io/components'
import StepContainer from '../../components/StepContainer'
import GrayTextWrapper from '../../components/GrayTextWrapper'

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    loansBenefintsTitle: {
      marginBottom: '50px !important',
    },
  }),
  { name: 'StepOne' }
)

const StepOne = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const classes = useStyles()

  // const {
  //   setStep
  // } = useContext(StepContext)

  const clickNext = (): void => {
    nextStep()
  }

  // useEffect(() => {
  //   setStep(1)
  // }, [])

  return (
    <StepContainer>
      <Typography variant='h6' fontWeight='bold'>
        הלוואה בקליק
      </Typography>

      <div className={classes.container}>
        <Typography variant='h4' fontWeight='bold' className={classes.loansBenefintsTitle}>
          הלוואה בטוחה ומותאמת אישית ב-7 דקות
        </Typography>

        <GrayTextWrapper>תוכלו לבחור להתחיל לשלם רק בעוד חצי שנה</GrayTextWrapper>
        <GrayTextWrapper>אפשרות להקפאת תשלום בקלות</GrayTextWrapper>
        <GrayTextWrapper>כבר היום ההלוואה תופיע בחשבון הבנק שלכם</GrayTextWrapper>
      </div>

      <Button label='לקבלת הלוואה תוך מספר דקות' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
    </StepContainer>
  )
}

export default StepOne
