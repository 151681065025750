import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, Divider, Drawer, Typography } from '@mui/material'
import { Button, LinkButton, Loader } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import formatToCurrency from '../../functions/formatToCurrency'
import StepContainer from '../../components/StepContainer'
import NumPadInput from '../../components/NumPad/NumPadInput'
import image from '../../assets/champagne.webp'
import updateLead from '../../functions/api/update-lead'

const useStyles = makeStyles(
  () => ({
    drawer: {
      '& .MuiPaper-root': {
        borderRadius: '1rem 1rem 0 0',
      },
    },
  }),
  { name: 'StepFourteen' }
)

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading, leadId } = useForm()
  const classes = useStyles()
  const [showDrawer, setShowDrawer] = useState(false)

  const clickNext = async (): Promise<void> => {
    if (formData['loanAmount']) {
      setLoading(true)

      try {
        const res = await updateLead(leadId, {
          creditRequested: Number(formData['loanAmount']),
        })

        if (res.status === 200) {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <div>
        <Avatar variant='circular' sx={{ width: '69px', height: '69px', margin: '0 auto 1rem auto' }} />
        <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', color: 'gray' }}>
          {formData['fullName'].split(' ')[0]}, ביקשת הלוואה על {formatToCurrency(Number(formData['loanAmount']))}
        </Typography>
        <img src={image} style={{ width: '150px', height: '150px', transform: 'scaleX(-1)' }} />
        <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', mb: 3 }}>
          אני שמחה לבשר לך שקיבלת עוד כסף להלוואה
        </Typography>
        <Typography variant='h5' fontWeight='bold' sx={{ direction: 'rtl' }}>
          יש לך אישור להלוואה בגובה {formData['loanAmount']} ₪
        </Typography>
        <LinkButton label='שנה את סכום ההלוואה' onClick={() => setShowDrawer(true)} />
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='בחר החזר החודשי שמתאים לך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}

      <Drawer anchor='bottom' open={showDrawer} onClose={() => setShowDrawer(false)} sx={{ zIndex: 9999 }} className={classes.drawer}>
        <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', textAlign: 'center', color: 'gray', margin: '1rem 0' }}>
          שנה את סכום ההלוואה
        </Typography>
        <Divider sx={{ mb: 3 }} />
        {/* <NumPad isCurrency placeholder='10,000' value={formData['loanAmount']} setValue={(v) => onChangeFormData('loanAmount', v)} /> */}
        <NumPadInput isCurrency placeholder='10,000' value={formData['loanAmount']} setValue={(v) => onChangeFormData('loanAmount', v)} />
        <Button label='אישור' onClick={() => setShowDrawer(false)} invert textAlign='center' style={{ width: '100%', borderRadius: '100px' }} />
      </Drawer>
    </StepContainer>
  )
}

export default DialogStep
