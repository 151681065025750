import axios from 'axios'
import { LOAN_JOURNEY_SERVICE } from '../../consts'

const updateLead = async (
  leadId: string,
  body: {
    businessId?: string
    businessName?: string
    businessType?: string
    businessEstablishedDate?: Date
    
    nationalId?: string
    fullAddress?: string
    
    creditType?: string
    creditRequested?: number
    creditTerm?: number
    creditPurpose?: string
    
    averageIncome?: number
    isBankrupt?: boolean
    hasFailedCheques?: boolean
    isFamilyOfPublicFigure?: boolean
    isForeignCitizen?: boolean
    isBusinessRepresentive?: boolean
    agreeToTerms?: boolean
    approvedInitialConditions?: boolean
    
    loanReturnStartDate?: Date
    shareBankDataExpiryDate?: Date
  }
): Promise<any> => await axios.patch(`${LOAN_JOURNEY_SERVICE}/lead/${leadId}`, body)

export default updateLead
