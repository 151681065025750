import React from 'react'
import { Avatar, Typography } from '@mui/material'
import { PersonPinCircleRounded as PersonPinCircleRoundedIcon } from '@mui/icons-material'

const GrayInfoBox = ({ title, description }: { title?: string; description?: string }): JSX.Element => {
  return (
    <div
      style={{
        padding: '0.3rem',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'whitesmoke',
      }}
    >
      <div>
        {title ? (
          <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', textAlign: 'right', fontSize: '0.9rem', color: 'black' }}>
            {title}
          </Typography>
        ) : null}

        {description ? (
          <Typography variant='body1' sx={{ direction: 'rtl', textAlign: 'right', fontSize: '0.9rem', color: 'gray' }}>
            {description}
          </Typography>
        ) : null}
      </div>

      <Avatar
        component={() => <PersonPinCircleRoundedIcon color='action' fontSize='large' sx={{ m: 1 }} />}
        style={{ backgroundColor: 'transparent' }}
      />
    </div>
  )
}

export default GrayInfoBox
