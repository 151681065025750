/* eslint react/no-unescaped-entities: 0 */
import React from 'react'
import { makeStyles } from '@mui/styles'
import { Divider, Typography } from '@mui/material'
import { Button, LinkButton } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import getHebrewMonth from '../../functions/getHebrewMonth'
import formatToCurrency from '../../functions/formatToCurrency'
import loanPlans from '../../data/loan-plans.json'
import banks from '../../data/bank-options.json'
import accounts from '../../data/bank-accounts-details.json'

const useStyles = makeStyles(
  () => ({
    rowGrey: {
      lineHeight: '2.5rem',
      backgroundColor: 'whitesmoke',
      '& strong': {
        margin: 'auto 0.5rem',
      },
      '& p': {
        margin: 'auto 0.5rem',
      },
    },
    rowNotGrey: {
      lineHeight: '2.5rem',
      '& strong': {
        margin: 'auto 0.5rem',
      },
      '& p': {
        margin: 'auto 0.5rem',
      },
    },
    rowAccount: {
      '& strong': {
        margin: 'auto 0.5rem',
      },
      '& p': {
        margin: 'auto 0.5rem',
      },
    },
    btnWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'StepTwentyTwo' }
)

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData } = useForm()
  const classes = useStyles()
  const selectedPlan = loanPlans.find((obj) => obj.id === formData['loanPlanId'])
  const selectedBank = banks.find((obj) => obj.id === formData['selectedBankId'])
  const selectedAccount = accounts.find((obj) => obj.id === formData['loanReceivingAccountId'])

  const getLoanMonthlyAmount = (): string => {
    const loanAmount = Number(formData['loanAmount'])

    if (!selectedPlan) return ''

    const loanWithInterest = loanAmount + (loanAmount + selectedPlan.interest) / 100
    const loanMonthlyAmount = Math.round(loanWithInterest / Number(selectedPlan.period))

    return loanMonthlyAmount + ' ₪'
  }

  const getLoanPlanInterest = (): string => {
    if (!selectedPlan) return ''

    return selectedPlan.interestType + ' + ' + selectedPlan.interest + '%'
  }

  const clickNext = (): void => {
    nextStep()
  }

  const getLastPaymentTimestamp = (): number => {
    const d = new Date(formData['loanReturnStartDate'])
    const year = d.getFullYear()
    const month = d.getMonth()
    const day = d.getDate()

    const thisMonth = new Date(year, month, day).getMonth()
    const timestamp = new Date(d).setMonth(thisMonth + (selectedPlan?.period ?? 0))

    return timestamp
  }

  return (
    <StepContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h5' fontWeight='bold' sx={{ direction: 'rtl', mb: 3 }}>
          אישור פרטי ההלוואה
        </Typography>

        <table dir='rtl' style={{ borderCollapse: 'collapse', textAlign: 'right' }}>
          <tbody>
            <tr className={classes.rowGrey}>
              <td>
                <strong>סכום ההלוואה</strong>
              </td>
              <td>
                <strong>{formatToCurrency(Number(formData['loanAmount']))}</strong>
              </td>
            </tr>
            <tr className={classes.rowGrey}>
              <td>
                <strong>החזר חודשי</strong>
              </td>
              <td>
                <p>{getLoanMonthlyAmount()}</p>
              </td>
            </tr>
            <tr className={classes.rowGrey}>
              <td>
                <strong>מספר תשלומים</strong>
              </td>
              <td>
                <p>{selectedPlan?.period}</p>
              </td>
            </tr>

            <tr className={classes.rowNotGrey}>
              <td>
                <strong>תשלום חודשי ראשון</strong>
              </td>
              <td>
                <p>{`${getHebrewMonth(new Date(formData['loanReturnStartDate']).getMonth())} ${new Date(
                  formData['loanReturnStartDate']
                ).getFullYear()}`}</p>
              </td>
            </tr>
            <tr className={classes.rowNotGrey}>
              <td>
                <strong>תשלום חודשי אחרון</strong>
              </td>
              <td>
                <p>{`${getHebrewMonth(getLastPaymentTimestamp())} ${new Date(getLastPaymentTimestamp()).getFullYear()}`}</p>
              </td>
            </tr>
            <tr className={classes.rowNotGrey}>
              <td>
                <strong>ריבית</strong>
              </td>
              <td>
                <p>{getLoanPlanInterest()}</p>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <Divider />
                <br />
              </td>
            </tr>

            <tr className={classes.rowAccount}>
              <td>
                <strong>פרטי חשבון להעברה</strong>
                <br />
                <br />
                <br />
                <br />
              </td>
              <td>
                <p>
                  בנק {selectedBank?.label}
                  <br />
                  סניף {selectedAccount?.branch}
                  <br />
                  {selectedAccount?.accountName}
                  <br />
                  {selectedAccount?.accountNumber}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes.btnWrapper}>
        <LinkButton label='תנאי ההלוואה ולוח תשלומים' fontSize='0.9rem' fontWeight={300} onClick={() => alert('TODO: handle this click event')} />
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      </div>
    </StepContainer>
  )
}

export default DialogStep
