import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

interface StyleProps {
  darker?: boolean
  bigger?: boolean
  noBorder?: boolean
}

const useStyles = makeStyles(
  () => ({
    item: {
      margin: '5px 0 !important',
      padding: ({ bigger }) => (bigger ? '1rem 0' : 5),
      backgroundColor: ({ darker }: StyleProps) => (darker ? 'lightgrey' : 'whitesmoke'),
      border: ({ noBorder, darker }: StyleProps) => (noBorder ? 'none' : darker ? '2px solid gray' : '2px solid lightgrey'),
      borderRadius: 11,
      fontWeight: ({ darker }: StyleProps) => (darker ? 'bold !important' : 'noraml !important'),
    },
  }),
  { name: 'GrayTextWrapper' }
)

interface Props extends StyleProps {
  children: JSX.Element | string | Array<JSX.Element | string | null>
}

const GrayTextWrapper = ({ children, darker, bigger, noBorder }: Props): JSX.Element => {
  const classes = useStyles({ darker, bigger, noBorder })

  return <Typography className={classes.item}>{children}</Typography>
}

export default GrayTextWrapper
