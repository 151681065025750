/* eslint-disable max-len */
import React from 'react'
import { makeStyles } from '@mui/styles'
import image from '../assets/arrows.png'

const useStyles = makeStyles(
  () => ({
    image: {
      width: '169px',
      height: '169px',
      animation: '$spin 2000ms linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(-360deg)',
      },
    },
    label: {
      color: 'grey',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
  { name: 'Loader' }
)

const Loader = ({ text }: { text: string }): JSX.Element => {
  const classes = useStyles()

  return (
    <div style={{ display: 'block', position: 'relative' }}>
      <img className={classes.image} alt='img' src={image} />
      <span className={classes.label}>{text}</span>
    </div>
  )
}

export default Loader
