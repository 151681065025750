import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0,0,0)',
    },
    background: {
      default: 'rgb(245,246,250)',
    },
  },
  typography: {
    fontFamily: [
      'Erica',
      'Sans',
      'Fot',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
  },
})

export default theme