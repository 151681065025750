import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { Button, Loader } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import GrayInfoBox from '../../components/GrayInfoBox'
import updateLead from '../../functions/api/update-lead'

const useStyles = makeStyles(
  () => ({
    row: {
      margin: '0.5rem 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      direction: 'rtl',
    },
    rowText: {
      marginLeft: 'auto',
    },
    selection: {
      width: '42px',
      height: '42px',
      margin: '0.3rem !important',
      border: '1px solid black',
      borderRadius: '7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    selected: {
      backgroundColor: 'lightgray',
      border: '2px solid black',
    },
  }),
  { name: 'StepSeventeen' }
)

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading, leadId } = useForm()
  const classes = useStyles()

  const clickNext = async (): Promise<void> => {
    setLoading(true)

    try {
      const res = await updateLead(leadId, {
        isFamilyOfPublicFigure: formData['isCloseToPublicFigure'],
        isForeignCitizen: formData['isForeignCitizen'],
        isBusinessRepresentive: formData['isRepresentingBusiness'],
      })

      if (res.status === 200) {
        setLoading(false)
        nextStep()
      } else {
        console.warn('unexpected response', res)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <StepContainer>
      <div>
        <Typography variant='h5' fontWeight='bold' sx={{ direction: 'rtl' }}>
          אימות לצורך היכרות
        </Typography>
        <Typography variant='h6' color='gray' fontSize='1rem' sx={{ direction: 'rtl', mt: 1, mb: 3 }}>
          לצורך אימות יש לענות על מספר שאלות:
        </Typography>
        <GrayInfoBox description='השאלות הינן שאלות רגולטריות. מענה עליהן יאפשר לנו לתת לך הלוואה.' />
      </div>

      <div>
        <div className={classes.row}>
          <span className={classes.rowText}>האם את/ה מקורב לאיש ציבור?</span>
          <span
            onClick={() => onChangeFormData('isCloseToPublicFigure', true)}
            className={`${classes.selection} ${formData['isCloseToPublicFigure'] ? classes.selected : ''}`}
          >
            כן
          </span>
          <span
            onClick={() => onChangeFormData('isCloseToPublicFigure', false)}
            className={`${classes.selection} ${!formData['isCloseToPublicFigure'] ? classes.selected : ''}`}
          >
            לא
          </span>
        </div>

        <div className={classes.row}>
          <span className={classes.rowText}>האם את/ה תושב/ת חוץ?</span>
          <span
            onClick={() => onChangeFormData('isForeignCitizen', true)}
            className={`${classes.selection} ${formData['isForeignCitizen'] ? classes.selected : ''}`}
          >
            כן
          </span>
          <span
            onClick={() => onChangeFormData('isForeignCitizen', false)}
            className={`${classes.selection} ${!formData['isForeignCitizen'] ? classes.selected : ''}`}
          >
            לא
          </span>
        </div>

        <div className={classes.row}>
          <span className={classes.rowText}>האם את/ה פועל/ת בשם התאגיד?</span>
          <span
            onClick={() => onChangeFormData('isRepresentingBusiness', true)}
            className={`${classes.selection} ${formData['isRepresentingBusiness'] ? classes.selected : ''}`}
          >
            כן
          </span>
          <span
            onClick={() => onChangeFormData('isRepresentingBusiness', false)}
            className={`${classes.selection} ${!formData['isRepresentingBusiness'] ? classes.selected : ''}`}
          >
            לא
          </span>
        </div>
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
