import axios from 'axios'
import { LOAN_JOURNEY_SERVICE } from '../../consts'

const createLead = async (body: {
  firstName: string
  lastName: string
  email: string
  phone: string
}): Promise<any> => await axios.post(`${LOAN_JOURNEY_SERVICE}/lead`, body)

export default createLead
