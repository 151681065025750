/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import Loader from '../../components/Loader'
import banks from '../../data/bank-options.json'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData } = useForm()
  const selectedBank = banks.filter((obj) => obj.id === formData['selectedBankId'])[0]

  useEffect(() => {
    setTimeout(() => {
      nextStep()
    }, 5000)
  }, [])

  return (
    <StepContainer style={{ placeContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '60%', justifyContent: 'space-around' }}>
        <div dir='rtl'>
          <Typography variant='h5' fontWeight='bold' sx={{ fontSize: 29 }}>
            לצורך השלמת התהליך נתחבר לכל בנק בנפרד
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={selectedBank?.imageUrl ?? ''} alt='' width={130} />
          <div style={{ paddingTop: 15 }}>
            <Loader text='...מתחבר' />
          </div>
        </div>
      </div>
    </StepContainer>
  )
}

export default DialogStep
