import React from 'react'
import axios from 'axios'
import { Typography } from '@mui/material'
import { Button, Loader } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import NumPadInput from '../../components/NumPad/NumPadInput'
import { LOAN_JOURNEY_SERVICE } from '../../consts'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading } = useForm()

  const clickNext = async (): Promise<void> => {
    if (formData['mobileNumber']) {
      setLoading(true)

      try {
        const res = await axios.post(`${LOAN_JOURNEY_SERVICE}/send-otp`, {
          phoneNumber: formData['mobileNumber'],
        })

        if (res.status === 200 && res.data.status === 'pending') {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <br />

      <div>
        <Typography variant='h5' fontWeight='bold' dir='rtl'>
          מה מספר הנייד שלך?
        </Typography>
        {/* <NumPad placeholder='050-000-0000' value={formData['mobileNumber']} setValue={(v) => onChangeFormData('mobileNumber', v)} /> */}
        <NumPadInput placeholder='050-000-0000' value={formData['mobileNumber']} setValue={(v) => onChangeFormData('mobileNumber', v)} />
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}
    </StepContainer>
  )
}

export default DialogStep
