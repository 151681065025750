import React from 'react'
import { Button } from '@everybyte-io/components'
import { makeStyles } from '@mui/styles'
import { Divider, Grid, Typography } from '@mui/material'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import banks from '../../data/bank-options.json'
import accounts from '../../data/bank-accounts-details.json'
import bankConnectTermOptions from '../../data/bank-connect-term-options.json'

const useStyle = makeStyles(() => ({
  detailsContainer: {
    padding: 10,
    backgroundColor: 'rgba(242, 242, 242, 0.4)',
    border: '2px solid rgb(242, 242, 242)',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
  },
}))

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const classes = useStyle()
  const { formData } = useForm()
  const selectedBank = banks.filter((obj) => obj.id === formData['selectedBankId'])[0]
  const selectedTerm = bankConnectTermOptions.filter((obj) =>
    typeof obj.value === 'number'
      ? obj.value === new Date(formData['shareBankDataExpiryDate']).getMonth() - new Date().getMonth()
      : new Date(formData['shareBankDataExpiryDate']).toLocaleDateString() === new Date().toLocaleDateString()
  )[0]

  const clickNext = (): void => {
    nextStep()
  }

  return (
    <StepContainer>
      <div dir='rtl' style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-evenly' }}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 4 }}>
            <img src={selectedBank?.imageUrl ?? ''} alt='' width={130} />
          </div>
          <Typography variant='h6' fontWeight='bold' sx={{ fontSize: 27, lineHeight: 1.3 }}>
            תהליך השיתוף עם
          </Typography>
          <Typography variant='h6' fontWeight='bold' sx={{ fontSize: 27, lineHeight: 1.3 }}>
            עם בנק לאומי הושלם בהצלחה!
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-evenly' }}>
          <Typography sx={{ color: 'gray', fontSize: 18 }}>להלן הפרטים שהסכמת לשתף:</Typography>
          <Grid container className={classes.detailsContainer}>
            <Grid item xs={5}>
              <Typography fontWeight='bold' textAlign='start'>
                סלי מידע
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={16} textAlign='start'>
                  פרטי חשבון
                </Typography>
                <Typography fontSize={16} textAlign='start'>
                  יתרות
                </Typography>
                <Typography fontSize={16} textAlign='start'>
                  תנועות בחשבון
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Divider light sx={{ display: 'flex', flex: 1, height: 10 }} />
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight='bold' textAlign='start'>
                זמן לשיתוף
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontSize={16} textAlign='start' padding={1}>
                {selectedTerm?.label}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.detailsContainer}>
            <Grid item xs={5}>
              <Typography fontWeight='bold' textAlign='start'>
                פרטי חשבונות
              </Typography>
            </Grid>

            <Grid item xs={7}>
              {accounts.map((obj) => (
                <div key={obj.id} style={{ margin: '0.5rem 0', display: 'flex', flexDirection: 'column' }}>
                  <Typography fontSize={16} textAlign='start'>
                    {selectedBank?.label} | {obj.branch}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Typography fontSize={16} textAlign='start'>
                      {obj.accountName}
                    </Typography>
                    <Typography fontSize={16} textAlign='start' fontWeight='bold' paddingRight={1}>
                      {obj.accountNumber}
                    </Typography>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>

      <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
    </StepContainer>
  )
}

export default DialogStep
