/* eslint no-unused-vars: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
import { makeStyles } from '@mui/styles'
import React from 'react'
import formatFromCurrency from '../../functions/formatFromCurrency'
import formatToCurrency from '../../functions/formatToCurrency'

const useStyles = makeStyles(
  () => ({
    inp: {
      width: '100%',
      padding: '0 0 0.5rem 0',
      margin: '0.5rem 0',
      border: 'none',
      borderBottom: '2px solid lightgrey',
      fontSize: '2rem',
      fontWeight: 'bold',
      textAlign: 'center',
      outline: 'none',
      '&::placeholder': {
        color: 'lightgray !important',
      },
    },
  }),
  { name: 'StepOne' }
)

const NumPadInput = ({
  placeholder,
  value,
  setValue,
  isCurrency,
}: {
  placeholder: string
  value: string
  setValue: (_value: string) => void
  isCurrency?: boolean
}): JSX.Element => {
  const classes = useStyles()

  return (
    <div>
      <input
        placeholder={placeholder}
        value={isCurrency && value && !isNaN(Number(value)) ? formatToCurrency(Number(value)) : value}
        onChange={(e) => {
          let v = e.target.value

          if (isCurrency) {
            v = formatFromCurrency(v)
          }

          setValue(v)
        }}
        className={classes.inp}
      />
    </div>
  )
}

export default NumPadInput
