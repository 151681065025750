import React from 'react'
import { Typography } from '@mui/material'

const Details = ({ title, value, horizontal, valueStyle = {} }: any): JSX.Element => (
  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: horizontal ? 'row' : 'column' }}>
    <Typography fontWeight='bold' textAlign='start'>
      {title}
    </Typography>
    <Typography fontSize={19} paddingRight={2} textAlign='start' sx={{ ...valueStyle }}>
      {value}
    </Typography>
  </div >
)

export default Details