import React from 'react'
import { Typography } from '@mui/material'
import { Button } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import NumPadInput from '../../components/NumPad/NumPadInput'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData } = useForm()

  const clickNext = (): void => {
    if (formData['nationalId']) {
      nextStep()
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <br />

      <div>
        <Typography variant='h5' fontWeight='bold' dir='rtl'>
          מה מספר תעודת הזהות שלך?
        </Typography>
        {/* <NumPad placeholder='000000000' value={formData['nationalId']} setValue={(v) => onChangeFormData('nationalId', v)} /> */}
        <NumPadInput placeholder='000000000' value={formData['nationalId']} setValue={(v) => onChangeFormData('nationalId', v)} />
      </div>

      <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
    </StepContainer>
  )
}

export default DialogStep
