import React from 'react'
import { Button } from '@everybyte-io/components'
import { Divider, Grid, Typography } from '@mui/material'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import bankAccountsDetails from '../../data/bank-accounts-details.json'
import banks from '../../data/bank-options.json'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData } = useForm()
  const selectedBank = banks.filter((obj) => obj.id === formData['selectedBankId'])[0]

  const clickNext = (): void => {
    if (formData['loanReceivingAccountId']) {
      nextStep()
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <div dir='rtl'>
        <Typography paragraph fontWeight='bold' sx={{ fontSize: 31 }}>
          לאיפה להעביר את הכסף?
        </Typography>
        <Typography paragraph sx={{ fontSize: 18, color: '#7F7F7F' }}>
          נוכל להעביר לאחד מהחשבונות שזוהו באמצעות הבנקאות הפתוחה
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '80%' }}>
          <Typography fontWeight='bold'>{selectedBank.label}</Typography>
          <Divider sx={{ marginBottom: 2 }} />
          {bankAccountsDetails.map((bankDetails) => (
            <Grid
              container
              key={bankDetails.id}
              onClick={() => onChangeFormData('loanReceivingAccountId', bankDetails.id)}
              style={{
                marginBottom: 2,
                padding: 8,
                backgroundColor: formData['loanReceivingAccountId'] === bankDetails.id ? 'rgb(215 211 211 / 54%)' : 'unset',
                border: formData['loanReceivingAccountId'] === bankDetails.id ? '2px solid #949494' : '1px #7f7f7f solid',
                borderRadius: '4px',
              }}
            >
              <Grid item xs={5} sx={{ alignSelf: 'center' }}>
                <img src={selectedBank?.imageUrl ?? ''} alt='' width={75} height={30} />
              </Grid>
              <Grid item xs={7}>
                <div dir='rtl'>
                  <Typography textAlign='start' sx={{ fontSize: 18 }}>
                    {selectedBank?.label} | {bankDetails.branch}
                  </Typography>
                  <Typography textAlign='start' sx={{ fontSize: 18 }}>
                    {bankDetails.accountName} | <b>{bankDetails.accountNumber}</b>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          ))}
        </div>
      </div>

      <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
    </StepContainer>
  )
}

export default DialogStep
