import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, Divider, Drawer, SvgIcon, Typography } from '@mui/material'
import {
  AccountBalance as AccountBalanceIcon,
  SyncAlt as SyncAltIcon,
  LocalAtm as LocalAtmIcon,
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
} from '@mui/icons-material'
import { Button, Loader } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import updateLead from '../../functions/api/update-lead'
import drawerOptions from '../../data/bank-connect-term-options.json'

const useStyles = makeStyles(
  () => ({
    infoWrapper: {
      margin: '5px 0 !important',
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
    },
    infoText: {
      marginLeft: 'auto !important',
    },
    selectButton: {
      width: '100%',
      padding: '0.5rem 1rem',
      backgroundColor: 'whitesmoke',
      border: '1px solid lightgrey',
      borderRadius: '11px',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    drawer: {
      '& .MuiPaper-root': {
        borderRadius: '1rem 1rem 0 0',
      },
    },
    drawerButton: {
      width: '90%',
      margin: '0.2rem auto',
      padding: '0.5rem 1rem',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '0.3rem',
      fontSize: '1rem',
      textAlign: 'right',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'lightgrey',
      },
    },
    drawerButtonSelected: {
      backgroundColor: 'lightgrey',
    },
  }),
  { name: 'StepNine' }
)

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData, loading, setLoading, leadId } = useForm()
  const classes = useStyles()
  const [showDrawer, setShowDrawer] = useState(false)

  const clickNext = async (): Promise<void> => {
    if (formData['shareBankDataExpiryDate']) {
      setLoading(true)

      try {
        const res = await updateLead(leadId, {
          shareBankDataExpiryDate: formData['shareBankDataExpiryDate'],
        })

        if (res.status === 200) {
          setLoading(false)
          nextStep()
        } else {
          console.warn('unexpected response', res)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  const InfoBox = ({ title, description, Icon }: { title: string; description: string; Icon: typeof SvgIcon }): JSX.Element => {
    return (
      <div className={classes.infoWrapper}>
        <div className={classes.infoText}>
          <Typography variant='body1' fontWeight='bold' style={{ direction: 'rtl' }}>
            {title}
          </Typography>
          <Typography variant='body1' fontSize='0.9rem' color='gray' style={{ direction: 'rtl' }}>
            {description}
          </Typography>
        </div>
        <Avatar
          component={() => <Icon color='action' sx={{ m: 1, p: 1, border: '1px solid gray', borderRadius: 100, fontSize: '3rem' }} />}
          style={{ backgroundColor: 'transparent' }}
        />
      </div>
    )
  }

  return (
    <StepContainer>
      <Typography variant='h5' fontWeight='bold'>
        שיתוף המידע עם הבנק
      </Typography>

      <div>
        <Typography variant='body1' fontWeight='bold' sx={{ direction: 'rtl', textAlign: 'right' }}>
          המידע שאנחנו מקבלים מהבנק שלך:
        </Typography>
        <InfoBox title='פרטי החשבון' description='שם בעלי החשבון, סניף ומספר חשבון' Icon={AccountBalanceIcon} />
        <InfoBox title='ריכוז יתרות' description='עובר ושב, חסכונות, פקדונות ואשראי' Icon={LocalAtmIcon} />
        <InfoBox title='תנועות בחשבון' description='פרטי ההכנסות וההוצאות' Icon={SyncAltIcon} />
        <Divider sx={{ mt: 3, mb: 4 }} />
        <Typography variant='body1' fontWeight='bold' sx={{ direction: 'rtl', textAlign: 'right', mb: 1 }}>
          לכמה זמן תרצו לשתף את המידע?
        </Typography>
        <button onClick={() => setShowDrawer(true)} className={classes.selectButton}>
          <KeyboardArrowDownRoundedIcon />
          {formData['shareBankDataExpiryDate']
            ? drawerOptions.find((obj) =>
              typeof obj.value === 'number'
                ? obj.value === new Date(formData['shareBankDataExpiryDate']).getMonth() - new Date().getMonth()
                : new Date(formData['shareBankDataExpiryDate']).toLocaleDateString() === new Date().toLocaleDateString()
            )?.label
            : 'בחר/י'}
        </button>
      </div>

      {loading ? (
        <Loader label='Loading...' />
      ) : (
        <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />
      )}

      <Drawer anchor='bottom' open={showDrawer} onClose={() => setShowDrawer(false)} sx={{ zIndex: 9999 }} className={classes.drawer}>
        <Typography variant='body1' fontWeight='bold' sx={{ direction: 'rtl', textAlign: 'center', m: 1 }}>
          לכמה זמן תרצו לשתף את המידע?
        </Typography>
        {drawerOptions.map((obj) => (
          <button
            key={obj.value}
            onClick={() => {
              if (obj.value !== 'custom date') {
                onChangeFormData(
                  'shareBankDataExpiryDate',
                  typeof obj.value === 'number'
                    ? new Date(new Date().getFullYear(), new Date().getMonth() + obj.value, new Date().getDate())
                    : obj.value === 'one time'
                      ? new Date()
                      : /* obj.value === 'custom date' ? '' : '' */ new Date()
                )
                setShowDrawer(false)
              }
            }}
            className={`${classes.drawerButton} ${
              typeof obj.value === 'number'
                ? obj.value === new Date(formData['shareBankDataExpiryDate']).getMonth() - new Date().getMonth()
                : new Date(formData['shareBankDataExpiryDate']).toLocaleDateString() === new Date().toLocaleDateString()
                  ? classes.drawerButtonSelected
                  : ''
            }`}
          >
            {obj.label}
          </button>
        ))}
      </Drawer>
    </StepContainer>
  )
}

export default DialogStep
