import React, { useState } from 'react'
import { Avatar, Modal, Typography } from '@mui/material'
// import { PersonPinCircleRounded as PersonPinCircleRoundedIcon } from '@mui/icons-material'
import { Button } from '@everybyte-io/components'
import { useForm } from '../../contexts/FormContext'
import StepContainer from '../../components/StepContainer'
import NumPadInput from '../../components/NumPad/NumPadInput'

const DialogStep = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { formData, onChangeFormData } = useForm()
  const [showModal, setShowModal] = useState(true)

  const clickNext = (): void => {
    if (formData['loanAmount']) {
      nextStep()
    } else {
      alert('אנא הזן את כל הפרטים')
    }
  }

  return (
    <StepContainer>
      <br />

      <div>
        <Typography variant='h5' fontWeight='bold' dir='rtl'>
          מהו סכום ההלוואה?
        </Typography>
        {/* <NumPad isCurrency placeholder='10,000' value={formData['loanAmount']} setValue={(v) => onChangeFormData('loanAmount', v)} /> */}
        <NumPadInput isCurrency placeholder='10,000' value={formData['loanAmount']} setValue={(v) => onChangeFormData('loanAmount', v)} />
      </div>

      <Button label='המשך' onClick={clickNext} invert textAlign='center' style={{ borderRadius: '100px' }} />

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div
          style={{
            maxWidth: '330px',
            padding: '1rem',
            backgroundColor: 'white',
            borderRadius: '1rem',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Avatar
            variant='circular'
            sx={{
              width: '69px',
              height: '69px',
              position: 'absolute',
              top: '-10%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />

          <Typography variant='h6' fontWeight='bold' sx={{ direction: 'rtl', textAlign: 'center', color: 'gray', margin: '3rem 0' }}>
            הי, שמי רוני, יועצת פיננסית.
            <br />
            אני אלווה אתכם בתהליך ואשמח לעזור בכל בעיה או שאלה
          </Typography>

          <Button label='תודה' onClick={() => setShowModal(false)} invert textAlign='center' style={{ width: '100%', borderRadius: '100px' }} />
        </div>
      </Modal>
    </StepContainer>
  )
}

export default DialogStep
